import { baseUrl } from "@/config";
import * as OM from '@/Model'

class _ExportServices {

    DownloadExport(fileId: string, name: string) {
        fileId = encodeURI(fileId);
        name = encodeURI(name);
        window.open(baseUrl + `api/Export/DownloadExport?fileId=${fileId}&name=${name}`, '_blank');
    }

    DownloadCsv(fileId: string, name: string) {
        fileId = encodeURI(fileId);
        name = encodeURI(name);
        window.open(baseUrl + `api/Export/DownloadPdf?fileId=${fileId}&name=${name}`, '_blank');
    }

    DownloadPdf(fileId: string, name: string) {
        fileId = encodeURI(fileId);
        name = encodeURI(name);
        window.open(baseUrl + `api/Export/DownloadPdf?fileId=${fileId}&name=${name}`, '_blank');
    }
    
}

export let ExportServices = new _ExportServices();
